
import React from 'react'
import { useTranslation } from "react-i18next"
export function ModalAvice({ unStaking }) {
    const [t, i18n] = useTranslation("global");
    return (
        <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header mx-auto">
                        <h3 class="modal-title text-warning" id="exampleModalLabel">{t("Staked.Warning")}</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p>{t("Staked.modal-unloack-desc")}</p>
                        <p>{t("Staked.modal-unloack-desc2")}</p>
                        <button type="button" class="btn btn-danger m-3" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-success m-3" onClick={unStaking} data-bs-dismiss="modal">{t("Staked.Confirm")}</button>
                    </div>



                </div>
            </div>
        </div>
    );
}

