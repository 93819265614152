import React from 'react'
import { getPorcentMes } from '../helper/helper';
import {useTranslation} from "react-i18next"
import NumberFormat from 'react-number-format';
export default function ModalCalc({appy}) {
    const[t,i18n] = useTranslation("global");
    const [days, setDay] = React.useState(14);
    const [diario, setDiario] =  React.useState(0);
    const [mensual, setMensual] =  React.useState(0);
    const [mtotal, setMtotal] =  React.useState(0);
    const apy = 100;
    const [values, setValues] = React.useState({
        amount: '',
      });
    const Meses = [3.5,4,4.8,5.6,6.5,8,9.4,10.4,11,11.5,12.4,12.9];
    let total = 0;
    let d =  days / 30
    for(let i=0; i<d; i++){
        total = total + (apy/100)*Meses[i];
    }
    ////NEXT DAY///
    const nextb = () =>{
        if(days < 360 ){
            setDay(days + 1)
        }else{
            setDay(360)
        }
    }
    ///PREV DAY/////
    const prevb = () =>{
       if(days > 14 ){
        setDay(days - 1)
       }else{
        setDay(14)
       }
    }
    const handleChange = (prop) => (event) => {
        console.log(event.target.value );
        setValues({ ...values, [prop]: event.target.value });
      };

      const montoDiario = ()=>{

        let dtt = getPorcentMes(days,values.amount,apy) 
        
        setDiario(dtt / days)
      }
      const montoMensual = ()=>{
        let dtt = getPorcentMes(days,values.amount,apy)  
        setMensual(dtt)
      }
      const montoTotal = ()=>{
   
        let dtt = getPorcentMes(days,values.amount,apy)  
        setMtotal(dtt)
      }
      React.useEffect(() => {
        montoDiario();
        montoMensual()
        montoTotal()
        }, [values,days]);

const myStyle = {
    fontSize: "10px",
    maxWidth:"60px",
    lineHeight:"15px"
  };
  return (
   <>
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">

                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>


                    <div className="modal-header-cal">
                        <h2>{t("calc.Calculate Profit")}</h2>
                        <p>{t("calc.Current APY")} {apy}%</p>
                    </div>



                    <div className="modal-main-cal">


                        <div className="cantidad">
                            <div className="cantidad-evu">
                                <h5>{t("Staked.Amount")}  Stake</h5>
                                <div className="data-value">
                                    <input type="number"  value={values.amount} onChange={handleChange('amount')}/>
                                    <span className="value">EVU</span>
                                </div>


                            </div>


                            <div className="component-day">
                                <div className="day-mes">

                                    <div className="day-mes__items row calmoveboton">
                                    <button style={myStyle} className='btn-evulus-small col-md-3 col-sm-3 p-1' onClick={e => setDay(90)}>{t("Staked.3 Month")}</button>
                                    <button style={myStyle} className='btn-evulus-small col-md-3 col-sm-3 p-1' onClick={e => setDay(180)}>{t("Staked.6 Months")}</button>
                                    <button style={myStyle} className='btn-evulus-small col-md-3 col-sm-3 p-1' onClick={e => setDay(270)}>{t("Staked.9 Months")}</button>
                                    <button style={myStyle} className='btn-evulus-small col-md-3 col-sm-3 p-1' onClick={e => setDay(360)}>{t("Staked.12 Months")}</button>
                                    </div>

                                </div>


                                <div className="slider-range">
                                    <div className="prev" onClick={prevb}></div>
                                    <div className="porcentaje">{days}</div>
                                    <div className="slider">
                                        <input className="range" type="range" min="14" max="360" 
                                        value={days}
                                        onChange={e => setDay(e.target.value)}
                                        step={1}></input>
                                    </div>
                                    <div className="next" onClick={nextb}></div>
                                </div>
                            </div>
                        </div>
                        
                  

                        <div className="montos">

                            <div className="data-value">

                                <input type="text" className="" value={t("calc.Daily Amount")}/><span class="value"><NumberFormat value={diario.toFixed(2)} displayType={'text'} thousandSeparator={true}/></span>
                            </div>
                           
                            <div className="data-value">

                                <input type="text" className="" value={t("calc.Total Amount")} /><span class="value"><NumberFormat value={mtotal.toFixed(2)} displayType={'text'} thousandSeparator={true}/></span>
                            </div>

                        </div>
                    </div>



                    <div className="modal-footer-cal">

                        <div className="component-mensual">

                            <div className="meses">
                                <div className="mes-title">{t("calc.Estimated Earning")}</div>

                                <div className="mes__items-container d-flex">

                                <div className="item-mes">
                                        <div className="item-mensual__number">1</div>
                                        <div className={days > 13 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[0]).toFixed(1)}</div>
                                    </div>
                                    <div className="item-mes">
                                        <div className="item-mensual__number">2</div>
                                        <div className={days > 30 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[1]).toFixed(1)}</div>
                                    </div>
                                    <div className="item-mes">
                                        <div className="item-mensual__number">3</div>
                                        <div className={days > 60 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[2]).toFixed(1)}</div>
                                    </div>
                                    <div className="item-mes">
                                        <div className="item-mensual__number">4</div>
                                        <div className={days > 90 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[3]).toFixed(1)}</div>
                                    </div>
                                    <div className="item-mes">
                                        <div className="item-mensual__number">5</div>
                                        <div className={days > 120 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[4]).toFixed(1)}</div>
                                    </div>
                                    <div className="item-mes">
                                        <div className="item-mensual__number">6</div>
                                        <div className={days > 150 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[5]).toFixed(1)}</div>
                                    </div>
                                    <div className="item-mes">
                                        <div className="item-mensual__number">7</div>
                                        <div className={days > 180 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[6]).toFixed(1)}</div>
                                    </div>
                                    <div className="item-mes">
                                        <div className="item-mensual__number">8</div>
                                        <div className={days > 210 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[7]).toFixed(1)}</div>
                                    </div>
                                    <div className="item-mes">
                                        <div className="item-mensual__number">9</div>
                                        <div className={days > 240 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[8]).toFixed(1)}</div>
                                    </div>
                                    <div className="item-mes">
                                        <div className="item-mensual__number">10</div>
                                        <div className={days > 270 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[9]).toFixed(1)}</div>
                                    </div>
                                    <div className="item-mes">
                                        <div className="item-mensual__number">11</div>
                                        <div className={days > 300 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[10]).toFixed(1)}</div>
                                    </div>
                                    <div className="item-mes">
                                        <div className="item-mensual__number">12</div>
                                        <div className={days > 330 ? "item-mensual__value active":"item-mensual__value"}>{((apy/100)*Meses[11]).toFixed(1)}</div>
                                    </div>
                                </div>

                            </div>


                            <div className="mensual-total ">
                                <div className="mensual-total__title">% Total</div>

                                <button type="button">{(total).toFixed(1)}</button>


                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>
        </>
  )
}
