import { useWeb3React } from "@web3-react/core"
import { isMobile } from 'react-device-detect';

import React, { useState, useEffect } from 'react';
import { injected, bcsscan } from "./components/wallet/connectors"
import './App.css';
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Header from './components/Header';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from './pages/Home';
import Staking from './pages/Staking';
import Admin from './pages/Admin';

///ejemplos
//https://app.metaverse.pro/#/stake
///https://app.btcst.finance/#/dashboard
///https://metaversepro.medium.com/congruent-migration-direction-bc305299dd97
//https://hodl.solchicks.io/

const App = () => {
  const { active, account, library, connector, activate, deactivate } = useWeb3React()
  const [activo, setActivo] = useState(localStorage.getItem('isWalletConnected'));
  const [logeado, setLogeado] = useState(localStorage.getItem('isWalletConnected'));
  const [walletConnectProvider, setWalletConnectProvider] = useState();
  const [web3Library, setWeb3Library] = useState();
  const [web3Account, setWeb3Account] = useState();
  const [selectwallet, setSelecwallet] = useState(localStorage.getItem('selectwallet'))
  const [stateModal, setStateModal] = useState(false)
  //hacer login con 3 wallet
  //1-metamash
  //2-bscscan
  //3-wallet conectec
  //0-deslogin
  const changered = async () => {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x38' }], // chainId must be in hexadecimal numbers

    });
  }

  async function connect(numlogin) {
    if (isMobile) {

      connectWaletConnect();

    } else {
      await changered()
      try {
        if (numlogin === 1) {
          await activate(injected)
          localStorage.setItem('isWalletConnected', true)
          localStorage.setItem('selectwallet', true)
          setSelecwallet(1)
          setLogeado(true)
        } else if (numlogin === 2) {
          await activate(bcsscan)
          localStorage.setItem('isWalletConnected', true)
          localStorage.setItem('selectwallet', true)
          setSelecwallet(2)
        }
      } catch (ex) {
        console.log(ex)
      }
    }
  }

  async function disconnect() {
    try {
      if (isMobile) {
        await disconnectWalletconnect();
        localStorage.setItem('isWalletConnected', false)
        setActivo(false)
        setLogeado(false)
        setSelecwallet(3)
      } else {
        await deactivate();
        localStorage.setItem('isWalletConnected', false)
        setLogeado(false)
      }

    } catch (ex) {
      console.log(ex)
    }
  }

  const disconnectWalletconnect = () => {

    walletConnectProvider.disconnect()
    setWalletConnectProvider(null);
    setWeb3Account(null)

  }

  //walletconnect
  const connectWaletConnect = async () => {
    setStateModal(false)
    try {
      const RPC_URLS = {
        1: 'https://bsc.getblock.io/ca97d618-d759-42c9-8e6b-c106ccdeb8d7/mainnet/'
      };
      const provider = new WalletConnectProvider({
        rpc: {
          56: RPC_URLS[1],
        },
        chainId: 56,
        supportedChainIds: [56],
        qrcode: true,
        pollingInterval: 15000
      });
      setWalletConnectProvider(provider);

      const accounts = await provider.enable();

      const account = accounts[0];

      const library = new Web3(provider);
      const chainId = await library.eth.getChainId();


      if (chainId == 56) {
        setWeb3Library(library);
        setWeb3Account(account);
        localStorage.setItem('isWalletConnected', true)
        localStorage.setItem('selectwallet', true)
        setActivo(true)
        setLogeado(true)
      } else {
        setStateModal(true)
        disconnect();
      }

      setSelecwallet(3)
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {

    const connectWalletOnPageLoad = async () => {
      if (localStorage?.getItem('isWalletConnected') === 'true') {
        try {
          if (isMobile) {
            if (window.location.pathname === "/") {

            } else {

              await connectWaletConnect();
              localStorage.setItem('isWalletConnected', true)
              localStorage.setItem('selectwallet', true)
              setActivo(true)
              setLogeado(true)
              setSelecwallet(3)
            }

          } else {
            await changered()
            await activate(injected)
            localStorage.setItem('isWalletConnected', true)
            setLogeado(true)
            setActivo(true)
          }

        } catch (ex) {
          console.log(ex)
        }
      }
    }
    if (activo) {
      connectWalletOnPageLoad()
    }

  }, [active, activo, logeado, web3Account, stateModal])
    if(isMobile){
   
      return (
        <div className="App"> 
         
        <BrowserRouter>
       
        <Header conectar={web3Account ? ()=>disconnect() : ()=>connect(3)}  active={web3Account}/>
          <Routes>
            <Route exact path="/staking" element={<Staking active={activo}  account={web3Account} library={web3Library} connector={connector} activate={activate} deactivate={deactivate}  calc={false} logeado={logeado} conectar={active ? ()=>disconnect() : ()=>connect(3)} redbsc={stateModal}/>} />
            <Route exact path="/admin" element={<Admin active={activo}  account={account} library={web3Library} connector={connector} activate={activate} deactivate={deactivate}  calc={false} logeado={logeado} conectar={active ? ()=>disconnect() : ()=>connect(3)} />} />
            <Route exact path="/" element={<Home/>} />
        </Routes>   
       
        </BrowserRouter>
       
        </div>
      );
    }else{
        return (
          <div className="App"> 
          <BrowserRouter>
          <Header conectar={active ? ()=>disconnect() : ()=>connect(1)}  active={active}/>
            <Routes>
              <Route exact path="/staking" element={<Staking active={active}  account={account} library={library} connector={connector} activate={activate} deactivate={deactivate}  calc={false} logeado={logeado} conectar={active ? ()=>disconnect() : ()=>connect(1)} />} />
              <Route exact path="/admin" element={<Admin active={active}  account={account} library={library} connector={connector} activate={activate} deactivate={deactivate}  calc={false} logeado={logeado} conectar={active ? ()=>disconnect() : ()=>connect(1)} />} />
              <Route exact path="/" element={<Home/>} />
          </Routes>   
          </BrowserRouter>
          </div>
        );
    }
 
} 
export default App;
