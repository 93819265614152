import React from 'react'
import Probit from '../helper/Probit';
import NumberFormat from 'react-number-format';
import { useTranslation } from "react-i18next"
export default function TitleStake({ account, evuapy, TVL, logeado }) {
    const [t] = useTranslation("global");
    const [evuprice, setEvuprice] = React.useState(0);
    Probit().then((web) => {
        setEvuprice(web);
    })

    return (<>
            <div className="title">
                <div className='image text-end pt-3'>
                    <a href="https://stakingv1.evulus.com/staking"><img className='text-end' src="../../assets/img/boton1.svg" width={200} /></a>
                </div>
                <h1 id="titulo">{t("title.Progressive Staking")}</h1>
                <h5 id="code">{account}</h5>
            </div>

            <div className="precios">
                <div className="apy container-btn-date">
                    <div className="btn-date">
                        <div className="precios__title">APY</div>
                        <span>{evuapy}%</span>
                    </div>

                </div>
                <div className="tvl  container-btn-date">
                    <div className="btn-date">
                        <div className="precios__title">TVL</div>
                        <span><NumberFormat value={TVL} displayType={'text'} thousandSeparator={true} /></span>
                    </div>
                </div>
                <div className="precio-evu  container-btn-date">
                    <div className="btn-date">

                        <div className="precios__title">{t("title.Price EVU")}</div>
                        <span>{evuprice}</span>
                    </div>
                </div>
            </div>
        </>);
}
